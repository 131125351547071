import http from "../http-common";
import authHeader from "./auth-header";

class MandantService {
    getAll() {
        return http.get("/mandants", { headers: authHeader() });
    }

    getMine() {
        return http.get("/mandants/mine/", { headers: authHeader() });
    }

    get(id) {
        return http.get(`/mandants/${id}`, { headers: authHeader() });
    }

    create(data) {
        return http.post("/mandants", data, { headers: authHeader() });
    }

    update(id, data) {
        return http.put(`/mandants/${id}`, data, { headers: authHeader() });
    }

    delete(id) {
        return http.delete(`/mandants/${id}`, { headers: authHeader() });
    }

    deleteAll() {
        return http.delete(`/mandants`, { headers: authHeader() });
    }

    findByFirmenbezeichnung(firmenbezeichnung) {
        return http.get(`/mandants?firmenbezeichnung=${firmenbezeichnung}`, { headers: authHeader() });
    }
}

export default new MandantService();