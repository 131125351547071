<template>
  <div class="list row">
    <div class="col-md-8">
      <div class="input-group mb-3">
        <input type="text" class="form-control" placeholder="Nach Firmenbezeichnung Kurz suchen"
               v-model="title"/>
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="button"
                  @click="searchFirmenbezeichnung"
          >
            Search
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <h4>Ihre Mandanten</h4>
      <ul class="list-group">
        <li class="list-group-item"
            :class="{ active: index === currentIndex }"
            v-for="(mandant, index) in mandants"
            :key="index"
            @click="setActive(mandant, index)"
        >
          {{ mandant.firmenbezeichnung.kurz }}
        </li>
      </ul>
    </div>
    <div class="col-md-6">
      <div v-if="currentMandant" class="mt-4">
        <h5>
          {{ currentMandant.firmenbezeichnung.kurz }}
        </h5>
        <dl class="m-1">
          <dt>ID:</dt>
          <dd>{{ currentMandant._id }}</dd>

          <dt>MDTS:</dt>
          <dd>{{ currentMandant.mdts }}</dd>

          <dt>Firmenbezeichnung Voll:</dt>
          <dd>{{ currentMandant.firmenbezeichnung.voll }}</dd>

          <dt>Firmenbezeichnung Kurz:</dt>
          <dd>{{ currentMandant.firmenbezeichnung.kurz }}</dd>

          <dt>Firmenzusatz:</dt>
          <dd>{{ currentMandant.firmenbezeichnung.zusatz }}</dd>
        </dl>

        <dl class="m-1">
          <dt>Inhaber:</dt>
          <dd>{{ currentMandant.inhaber.titel }} {{ currentMandant.inhaber.vorname }} {{ currentMandant.inhaber.name }}</dd>

          <dt>Geschäftsführer:</dt>
          <dd>{{ currentMandant.gFuehrer.titel }} {{ currentMandant.gFuehrer.vorname }} {{ currentMandant.gFuehrer.name }}</dd>
        </dl>

        <dl class="m-1">
          <dt>Straße:</dt>
          <dd>{{ currentMandant.adresse.strasse }}</dd>

          <dt>Postleitzahl:</dt>
          <dd>{{ currentMandant.adresse.plz }}</dd>

          <dt>Ort:</dt>
          <dd>{{ currentMandant.adresse.ort }}</dd>
        </dl>

        <dl class="m-1">
          <dt>Internetseite:</dt>
          <dd>{{ currentMandant.kontakt.web }}</dd>

          <dt>Telefon:</dt>
          <dd>{{ currentMandant.kontakt.telefon }}</dd>

          <dt>Fax:</dt>
          <dd>{{ currentMandant.kontakt.fax }}</dd>

          <dt>E-Mail Adresse:</dt>
          <dd>{{ currentMandant.kontakt.email }}</dd>
        </dl>

        <router-link :to="'/mandants/' + currentMandant._id" class="badge badge-warning">Bearbeiten</router-link>
      </div>
      <div v-else>
        <br />
        <p>Wählen Sie links einen Mandanten aus, um seine Details zu sehen.</p>
      </div>
    </div>
  </div>
</template>

<script>
import MandantService from "../services/mandant.service";

export default {
  name: "mandants-list",
  data() {
    return {
      mandants: [],
      currentMandant: null,
      currentIndex: -1,
      title: ""
    };
  },
  methods: {
    retrieveInstances() {
      MandantService.getMine()
          .then(response => {
            this.mandants = response.data;
            console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          });
    },

    refreshList() {
      this.retrieveInstances();
      this.currentMandant = null;
      this.currentIndex = -1;
    },

    setActive(tutorial, index) {
      this.currentMandant = tutorial;
      this.currentIndex = tutorial ? index : -1;
    },

    searchFirmenbezeichnung() {
      MandantService.findByFirmenbezeichnung(this.title)
          .then(response => {
            this.mandants = response.data;
            this.setActive(null);
            console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          });
    }
  },
  mounted() {
    this.retrieveInstances();
  }
};
</script>

<style>
.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}
</style>